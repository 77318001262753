<template>
    <div>
        <app-paginated-table ref="userList" :fields="fields" :items="items" :current-page="current_page" :total-pages="total_pages" :total="total_records" :loading="loading" @onPageChange="onPageChange" @onRowClicked="viewUserDetails" @onLoadMore="loadMoreUsers" :mobileHeaders="['email','actions']">
            <template #header>
                <b-tabs @input="onTabChange" class="usersTabs text-nowrap">
                    <b-tab title="All" active></b-tab>
                    <b-tab title="Clients" value="client"></b-tab>
                    <b-tab title="Service Providers" class="text-nowrap" value="service_provider"></b-tab>
                    <!-- <b-tab title="Brokers" value="broker"></b-tab> -->
                </b-tabs>   
            </template>
            <template #filters>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <app-text-input v-model="filters.search" placeholder="Search" @input="onFilterUpdate()"></app-text-input>
                    </div>
                    <div class="col-12 col-md-4">
                        <app-select-box v-model="filters.status" :options="options" @onChange="onFilterUpdate()"></app-select-box>
                    </div>
                </div>
            </template>
            <template #head(actions)>
                <!-- <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                /> -->
                {{ '' }}
            </template>
            <template #cell(company)="data">
                {{ data.item.companyName?data.item.companyName:'N/A' }}
            </template>
            <template #cell(roles)="data">
                <span  v-for="(role,index) in data.item.roles " :key="index">{{toCapitalize(role)}}<br></span>
                <!-- {{ toCapitalize(data.item.role) }} -->
            </template>
            <!-- <template #cell(name)="data">
                {{ data.item.first_name }} {{ data.item.last_name }}
            </template> -->
            <template #cell(user_id)="data">
                {{ data.item.userId?data.item.userId:'N/A' }}
            </template>
            <template #cell(support_tickect)="data">
                {{ data.item.supportQueries.length }}
            </template>
            <template #cell(actions)="data">
                <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Block Level Dropdown Menu"
                right
                variant="none"
                menu-class="w-100"
                size="sm"
                no-caret
                >
                <template #button-content>
                    <feather-icon
                        icon="MoreVerticalIcon"
                        size="18"
                    />
                </template>
                <b-dropdown-item @click="viewUserDetails(data.item)">View Details</b-dropdown-item>
                <b-dropdown-item v-if="data.item.status=='active'" @click="confirmDelete(data.item._id)">Remove User</b-dropdown-item>
                <app-confirm-modal ref="deleteModal" heading="Remove User" cancel-text="No" confirm-text="Yes Remove" @onConfirm="deleteUser()">Are you sure you want to remove user?</app-confirm-modal>
                <b-dropdown-item @click="editUser(data.item)">Edit User</b-dropdown-item>
                </b-dropdown>
                
            </template>
            <template #cell(status)="data">
                <b-badge :variant="data.item.status=='active'? 'light-success':data.item.status=='pending_signup'?'light-secondary':data.item.status=='pending_verification'?'light-warning': 'light-danger'">
                    {{ data.item.status=='active'? 'Active':data.item.status=='pending_verification'?'Verification Pending':data.item.status=='pending_signup'?'Signup Pending': 'In Active' }}
                </b-badge>
            </template>
            <template>
            </template>
        </app-paginated-table>
        <user-details ref="userDetails" @onDelete="confirmDelete" @onEdit="editUser"/>
        <!-- <b-sidebar v-model="showSidebar" backdrop backdrop-variant="light" width="531px" bg-variant="white" right no-header-close></b-sidebar> -->
        <update-user ref="updateUser" @onUpdated="getUsers()"></update-user>
    </div>
</template>
<script>
import {BTabs,BTab,BCard,BTableLite, BDropdown, BDropdownItem, BBadge,BSidebar} from 'bootstrap-vue'
import AppPaginatedTable from '@/components/app-components/AppPaginatedTable.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppSelectBox from '@/components/app-components/AppSelectBox.vue'
import AppConfirmModal from '@/components/app-components/AppConfirmModal.vue'
import UpdateUser from '@/components/functional-components/admin/users/UpdateUser.vue'
import UserDetails from '@/components/functional-components/admin/users/UserDetails.vue'
import {delete_user,update_user, get_users} from '@/apis/admin/users'
import Ripple from 'vue-ripple-directive'
export default{
    directives:{
        Ripple
    },
    components:{
        BTabs,
        BTab,
        BCard,
        BTableLite,
        AppPaginatedTable,
        AppTextInput,
        BDropdown,
        BDropdownItem,
        BBadge,
        AppSelectBox,
        AppConfirmModal,
        UpdateUser,
        BSidebar,
        UserDetails
    },
    data(){
        return{
            search:'',
            status_filter:{
                active:false,
                disabled:false  
            },
            fields:[
                {
                    key:'user_id',
                    label:'User ID'
                },
                // {
                //     key:'name',
                //     label:'Name'
                // },
                {
                    key:'email',
                    label:'Email'
                },
                {
                    key:'roles',
                    label:'Role'
                },
                {
                    key:'company',
                    label:'Company'
                },
                {
                    key:'support_tickect',
                    label:'Support Tickects',
                },
                {
                    key:'status',
                    label:'Status'
                },
                {
                    key:'actions'
                }
            ],
            items: [],
            filters:{
                page:1,
                limit:10,
                search:'',
                status:''
            },
            current_page:1,
            total_records:0,
            total_pages:0,
            status:'',
            loading:false,
            delete_id:'',
            options: [
                { value: '', text: 'Status' },
                { value: 'active', text: 'Active' },
                { value: 'inactive', text: 'Inactive' },
                { value: 'pending_signup', text: 'Signup Pending' },
                { value: 'pending_verification', text: 'Verification Pending' },
            ],
            showSidebar:false,
            loadMore:false,
        }
    },
    mounted(){
        this.getUsers()
    },
    methods:{
        onTabChange(tab){
            this.filters['role']=tab==1?'client':tab==2?'service_provider':''
            this.filters.page=1;
            this.getUsers()
        },
        onPageChange(page){
            this.filters.page=page
            this.getUsers()
        },
        getUsers(){
            this.loading=true;
            get_users(this.filters).then(response=>{
                this.loading=false;
                this.items=this.loadMore?[...this.items,...response.data.users]:response.data.users
                this.current_page=Number(response.data.currentPage)
                this.total_records=response.data.usersCount
                this.total_pages=response.data.usersCount>0?response.data.usersCount/this.filters.limit:0
                this.loadMore=false
                this.$refs.userList.loadMore=false
            }).catch(e=>{
                this.loading=false;
                this.loadMore=false
                console.log(e)
            })
        },
        deleteUser(){
            delete_user(this.delete_id).then(response=>{
                this.getUsers()
            }).catch(e=>{
                this.$bvToast.toast(err.message, {
                    title: err.error,
                    variant: 'danger',
                    solid: true,
                })
            })
        },
        updateUsers(id,data){
            update_user(id,data).then(response=>{
                console.log(response)
            }).catch(e=>{
                console.log(e)
            })
        },
        confirmDelete(id){
            this.delete_id=id
            this.$refs.deleteModal.openModal()
        },
        editUser(data){
            this.$refs.updateUser.showModal(data);
        },
        toCapitalize(name){
            let words = name.split("_")
            let upperCaseStr= words.map(w=>`${w.charAt(0).toUpperCase()}${w.slice(1)}`).join(" ")
            return upperCaseStr
        }, 
        viewUserDetails(details){
            this.$refs.userDetails.showDetails(details)
        },
        onFilterUpdate(){
            this.filters.page=1
            this.getUsers()
        },
        loadMoreUsers(){
            this.filters.page+=1
            this.getUsers()
            this.loadMore=true
        }
    }
}
</script>
<style>
.usersTabs ul .nav .nav-pills{
    flex-wrap: nowrap !important;
    overflow-x:auto !important;
}
</style>